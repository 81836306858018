import React from 'react'
import AssetMedia from 'components/AssetMedia'
import useAssetThumbnail, { FALLBACK_SRC } from './AssetThumbnail.hooks'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { AccountAsset } from 'types/node'
import TokenImage from 'components/DetailView/Assets/AssetPreview/TokenImage'

interface AssetThumbnailProps {
  asset: AccountAsset
  className?: string
}

function AssetThumbnail({ asset, className = '' }: AssetThumbnailProps) {
  const src = useAssetThumbnail(asset)

  const renderThumbnail = () => {
    if (!src) {
      return (
        <div className="h-12 w-12 bg-gray-200 rounded-full animate-strong-pulse dark:bg-gray-750" />
      )
    }

    const description = asset.name || asset.unitName || asset.id
    const alt = `${description} thumbnail`

    if (src === FALLBACK_SRC) {
      return <TokenImage alt={alt} />
    }

    return (
      <AssetMedia
        src={src}
        alt={alt}
        loader={galleryImageLoader}
        width={48}
        height={48}
        unoptimized={false}
        options={{ stateBgColor: 'transparent', showVideoIcon: false }}
        videoJsOptions={{
          preload: 'none',
          controls: false,
          autoplay: false,
          loop: true,
          muted: true,
          aspectRatio: '1:1'
        }}
      />
    )
  }

  return <div className={className}>{renderThumbnail()}</div>
}

export default React.memo(AssetThumbnail)
