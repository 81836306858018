import { type SendToVaultRequestBody, nfdSendToVault } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'
import type { SendModalContext } from 'components/SendModal/SendModal.types'

export type SendToVaultParams = {
  name: string
  body: SendToVaultRequestBody
}

type SendToVaultOptions = MutationOptions<SendToVaultParams, SendModalContext>

export function usePostSendToVault(options: SendToVaultOptions = {}) {
  return usePostTransaction<SendToVaultParams, SendModalContext>({
    mutationFn: ({ name, body }) => nfdSendToVault(name, body),
    ...options
  })
}
