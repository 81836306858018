import { NfdRecord } from 'api/api-client'
import { getAvatarURL, isAvatarVerified } from 'helpers/avatar'
import { classNames } from 'helpers/utilities'
import React from 'react'
import AssetMedia from 'components/AssetMedia'
import galleryImageLoader from 'helpers/galleryImageLoader'

interface AvatarProps {
  nfd: NfdRecord
  size?: 'sm' | 'md' | 'lg'
  bg?: 'dark' | 'light'
  isPadded?: boolean
}

function Avatar({ nfd, size = 'md', bg, isPadded = false }: AvatarProps) {
  const isVerified = isAvatarVerified(nfd)

  const getSizeClasses = (verified?: boolean) => {
    if (size === 'sm') {
      return verified ? 'h-7 w-7' : 'h-5 w-5'
    }

    if (size === 'md') {
      return verified ? 'h-10 w-10' : 'h-8 w-8'
    }

    if (size === 'lg') {
      return verified ? 'h-16 w-16' : 'h-14 w-14'
    }

    return ''
  }

  const getSizes = () => {
    if (size === 'sm') {
      return '28px'
    }

    if (size === 'md') {
      return '40px'
    }

    if (size === 'lg') {
      return '64px'
    }

    return ''
  }

  const getRingClasses = () => {
    if (isVerified) {
      switch (bg) {
        case 'dark':
          return 'ring-2 ring-gray-900'
        case 'light':
          return 'ring-2 ring-white'
        default:
          return 'ring-2 ring-white dark:ring-gray-900'
      }
    }

    return isPadded ? 'ring-2 ring-transparent' : ''
  }

  const AvatarImage = () => (
    <div
      className={classNames(
        getSizeClasses(),
        getRingClasses(),
        'relative rounded-full bg-gray-200 overflow-hidden dark:bg-gray-700'
      )}
    >
      <AssetMedia
        src={getAvatarURL(nfd)}
        alt={nfd.name}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes={getSizes()}
        fill
      />
    </div>
  )

  if (isVerified) {
    return (
      <div
        className={classNames(
          getSizeClasses(true),
          'flex items-center justify-center rounded-full bg-gradient-to-br from-violet-600 to-pink-500'
        )}
      >
        <AvatarImage />
      </div>
    )
  }

  if (isPadded) {
    return (
      <div
        className={classNames(
          getSizeClasses(true),
          'flex items-center justify-center rounded-full bg-transparent'
        )}
      >
        <AvatarImage />
      </div>
    )
  }

  return <AvatarImage />
}

export default React.memo(Avatar)
