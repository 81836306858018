import Link from 'next/link'
import { BiCopy, BiWallet } from 'react-icons/bi'
import { HiArrowUpRight } from 'react-icons/hi2'
import Button from 'components/Button'
import ButtonLink from 'components/ButtonLink'
import PriceTag from 'components/PriceTag'
import Tooltip from 'components/Tooltip'
import Avatar from 'components/UserThumbnail/Avatar'
import UserThumbnail from 'components/UserThumbnail'
import SocialLinks from './SocialLink'
import { useAdvancedSearchStore } from 'store/state/advancedSearch'
import { copyToClipboard } from 'helpers/copyToClipboard'
import { truncateAddress } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

interface NfdCardProps {
  nfd: NfdRecord
  activeAddress: string | undefined
  onSendClick: (receiver: string) => void
  onViewClick: () => void
}

export default function NfdCard({ nfd, activeAddress, onSendClick, onViewClick }: NfdCardProps) {
  const addToRecent = useAdvancedSearchStore((state) => state.addToRecent)

  const handleSendClick = () => {
    onSendClick(nfd.name)
    addToRecent(nfd.name)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter') {
      onViewClick()
      addToRecent(nfd.name)
    }
  }

  const handleAnchorClick = () => {
    onViewClick()
    addToRecent(nfd.name)
  }

  const handleCopyAddress = (event: React.MouseEvent<HTMLButtonElement>) => {
    copyToClipboard(event)
    addToRecent(nfd.name)
  }

  const renderAddress = (address: string | undefined) => {
    if (!address) {
      return <span className="block ml-1 h-5 text-gray-500">N/A</span>
    }

    const addr = truncateAddress(address, { array: true })

    return (
      <div className="flex w-full items-center justify-between">
        <Link
          href={`/address/${address}`}
          className="inline-flex items-center text-sm whitespace-nowrap text-gray-900 hover:text-brand-500 font-mono outline-brand-500 rounded-md px-1 dark:text-gray-400 dark:hover:text-brand-500"
          onKeyDown={handleKeyDown}
          onClick={handleAnchorClick}
        >
          <BiWallet className="flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
          {addr[0]}&hellip;{addr[1]}
        </Link>
        <Tooltip text="Copy">
          <button
            type="button"
            className="relative inline-flex items-center rounded-md shadow-sm border border-gray-300 bg-white px-1.5 py-1.5 -my-1 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 outline-brand-500 dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700"
            data-clipboard-text={address}
            onClick={handleCopyAddress}
          >
            <span className="sr-only">Copy address</span>
            <BiCopy className="h-4 w-4" aria-hidden="true" />
          </button>
        </Tooltip>
      </div>
    )
  }

  const renderInfo = () => {
    if (nfd.state === 'forSale') {
      return (
        <>
          {nfd.sellAmount && (
            <>
              <dt className="inline-flex items-center col-end-1 font-semibold text-gray-900 dark:text-gray-100">
                Sale price
              </dt>
              <dd className="inline-flex items-center">
                <PriceTag price={nfd.sellAmount} />
              </dd>
            </>
          )}
          {nfd.seller && (
            <>
              <dt className="inline-flex items-center col-end-1 font-semibold text-gray-900 dark:text-gray-100">
                Seller
              </dt>
              <dd className="inline-flex items-center">
                <UserThumbnail address={nfd.seller} size="sm" />
              </dd>
            </>
          )}
        </>
      )
    }

    return (
      <>
        <dt className="inline-flex items-center col-end-1 font-semibold text-gray-900 dark:text-gray-100">
          Deposit address
        </dt>
        <dd className="inline-flex items-center">{renderAddress(nfd.depositAccount)}</dd>
        <dt className="inline-flex items-center col-end-1 font-semibold text-gray-900 dark:text-gray-100">
          Vault address
        </dt>
        <dd className="inline-flex items-center">{renderAddress(nfd.nfdAccount)}</dd>
        <dt className="inline-flex items-center col-end-1 font-semibold text-gray-900 dark:text-gray-100">
          Social links
        </dt>
        <dd className="flex items-center h-5 text-gray-500">
          <SocialLinks nfd={nfd} />
        </dd>
      </>
    )
  }

  const renderActions = () => {
    if (nfd.state === 'forSale' || nfd.state === 'reserved') {
      return (
        <div className="grid grid-cols-1">
          <ButtonLink
            href={`/name/${nfd.name}`}
            onKeyDown={handleKeyDown}
            onClick={handleAnchorClick}
          >
            View listing
          </ButtonLink>
        </div>
      )
    }

    return (
      <div className="grid grid-cols-2 gap-3">
        <ButtonLink
          href={`/name/${nfd.name}`}
          onKeyDown={handleKeyDown}
          onClick={handleAnchorClick}
        >
          View profile
        </ButtonLink>
        <Button
          variant="primary"
          icon={HiArrowUpRight}
          onClick={handleSendClick}
          disabled={!activeAddress}
        >
          Send
        </Button>
      </div>
    )
  }

  return (
    <div className="absolute inset-0 bg-white md:h-96 md:static md:w-1/2 flex flex-col dark:bg-gray-900">
      <div className="flex-1 flex flex-col divide-y divide-gray-100 overflow-y-auto dark:divide-gray-800">
        <div className="flex-none px-6 pt-3 pb-4 text-center">
          <div className="flex justify-center">
            <Link
              href={`/name/${nfd.name}`}
              className="flex flex-col items-center justify-center space-y-1 pt-1 px-1 rounded-md font-semibold text-gray-900 outline-brand-500 hover:text-brand-500 dark:text-gray-100 dark:hover:text-brand-500"
            >
              <Avatar nfd={nfd} size="lg" isPadded />
              <span className="truncate">{nfd.name}</span>
            </Link>
          </div>
          <p className="text-sm leading-6 text-gray-500 truncate">
            {nfd?.properties?.userDefined?.name}
          </p>
        </div>
        <div className="flex flex-auto flex-col justify-between p-6">
          <dl className="grid grid-cols-1 gap-x-6 gap-y-4 text-sm text-gray-700 dark:text-gray-400">
            {renderInfo()}
          </dl>
          {renderActions()}
        </div>
      </div>
    </div>
  )
}
