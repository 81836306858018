import { useRouter } from 'next/router'
import { useState } from 'react'
import MobileNav from 'components/Layout/MobileNav'
import DesktopNav from 'components/Layout/DesktopNav'
import Header from './Header'
import MenuButton from './MenuButton'
import TosBanner from './TosBanner'
import useNoCacheNames from 'hooks/useNoCacheNames'

export default function Layout({ children }: { children: React.ReactNode }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const router = useRouter()
  const pathname = router.pathname

  // @todo: remove this, see TXN-1545
  useNoCacheNames()

  return (
    <>
      <div className="h-full flex">
        <MobileNav pathname={pathname} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <DesktopNav pathname={pathname} />

        <div className="lg:pl-64 flex flex-col w-0 flex-1 min-h-screen">
          <div className="sticky top-0 z-50 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-gray-500/20">
            <MenuButton setSidebarOpen={setSidebarOpen} />
            <Header />
          </div>

          {children}
        </div>
      </div>

      <TosBanner />
    </>
  )
}
