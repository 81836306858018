import { type SendFromVaultRequestBody, nfdSendFromVault } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'
import type { SendModalContext } from 'components/SendModal/SendModal.types'

export type SendFromVaultParams = {
  name: string
  body: SendFromVaultRequestBody
}

type SendFromVaultOptions = MutationOptions<SendFromVaultParams, SendModalContext>

export function usePostSendFromVault(options: SendFromVaultOptions = {}) {
  return usePostTransaction<SendFromVaultParams, SendModalContext>({
    mutationFn: ({ name, body }) => nfdSendFromVault(name, body),
    ...options
  })
}
