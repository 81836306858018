import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import algosdk from 'algosdk'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useAccountAsset from 'api/hooks/node/useAccountAsset'
import useAccountInfo from 'api/hooks/node/useAccountInfo'
import { usePostSendFromVault } from 'api/hooks/usePostSendFromVault'
import { usePostSendToVault } from 'api/hooks/usePostSendToVault'
import { usePostSendToAccount } from 'api/hooks/usePostSendToAccount'
import { isAssetOptedIn } from 'helpers/node'
import { convertMicroalgosToAlgos, convertToBaseUnits, isValidName } from 'helpers/utilities'
import useErrorToast from 'hooks/useErrorToast'
import { isAlgoAsset, isAsaAsset, isAssetOneOfOne } from './SendModal.utils'
import SendSuccessToast from './SendSuccessToast'
import type { FormState, SendModalContext, SendModalProps, Sender } from './SendModal.types'
import type { NfdRecord } from 'api/api-client'
import type { ReceiverType } from 'components/NfdLookup/NfdLookup.types'
import type { AccountAsset, SendToAccountParams } from 'types/node'

type UseSendModal = Omit<SendModalProps, 'children' | 'showNote'>

export default function useSendModal(props: UseSendModal) {
  const [isOpen, setIsOpen] = useModalState(props)
  const [isSending, setIsSending] = useState(false)

  const [receiverNfd, setReceiverNfd] = useState<NfdRecord | undefined>(
    typeof props.receiver === 'object' ? props.receiver : undefined
  )

  const { activeAddress, activeWalletAddresses } = useWallet()

  const handleError = useErrorToast()
  const queryClient = useQueryClient()

  const closeButtonRef = useRef(null)

  const { sender, setSender, senders } = useSender({
    senderProp: props.sender
  })

  const { asset, selectedAsset, setSelectedAsset, algoBalances, isLoading } = useAsset({
    assetProp: props.asset,
    sender,
    isOpen
  })

  const { formState, setFormState, defaultState } = useFormState({ props, asset })

  const resetState = useCallback(() => {
    setFormState(defaultState)
    setReceiverNfd(undefined)
    setSelectedAsset(undefined)
    setSender(senders[0])
  }, [defaultState, senders, setFormState, setSelectedAsset, setSender])

  const receiverCanSign = useMemo(
    () => activeWalletAddresses?.includes(formState.receiver) || false,
    [activeWalletAddresses, formState.receiver]
  )

  const { isFieldValid, isSubmitDisabled, showAmountValidState, error } = useValidation({
    formState,
    sender,
    asset,
    receiverCanSign,
    algoBalances,
    isOpen
  })

  const handleOpen = () => {
    setFormState(defaultState)
    setIsOpen(true)
  }

  const handleClose = useCallback(() => {
    setIsOpen(false)

    setTimeout(() => {
      props.onClose?.()
      resetState()
    }, 300)
  }, [props, resetState, setIsOpen])

  // Manually close modal on Escape key press
  // @see https://github.com/tailwindlabs/headlessui/issues/1751#issuecomment-1528876192
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        handleClose()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClose, isOpen])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // eslint-disable-next-line prefer-const
    let { name, value } = event.target

    if (name === 'amount' && asset) {
      // matches integers or floats up to ${asset.decimal} decimal places
      const regExp =
        asset.decimals === 0
          ? /^\d+$/gm
          : new RegExp(`^\\d+(?:\\.\\d{0,${asset.decimals}})?$`, 'gm')

      if (value === '.') {
        value = '0.'
      }

      if (value !== '' && value.match(regExp) === null) {
        return
      }
    }

    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleChangeReceiver = (receiver: string) => {
    setFormState((prevState) => ({
      ...prevState,
      receiver
    }))
  }

  const handleChangeReceiverType = (receiverType: ReceiverType) => {
    setFormState((prevState) => ({
      ...prevState,
      receiverType
    }))
  }

  const handleSetMaxAmount = () => {
    if (!asset) return

    if (isAlgoAsset(asset)) {
      try {
        if (!algoBalances) {
          throw new Error('Sender account minimum balance requirement (MBR) unavailable')
        }

        const maxAmount = convertMicroalgosToAlgos(algoBalances.maxSpend)

        setFormState((prevState) => ({
          ...prevState,
          amount: maxAmount.toString()
        }))
      } catch (error) {
        handleError(error)
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        amount: asset.amount.toString()
      }))
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsSending(true)

    try {
      if (!activeAddress) {
        throw new Error('Wallet not connected')
      }

      // Send from vault
      if (sender.nfd) {
        await handleSendFromVault(sender.nfd)
      }

      // Send to vault
      else if (formState.receiverType === 'nfdVault') {
        await handleSendToVault()
      }

      // Send to account
      else {
        await handleSendToAccount()
      }

      handleClose()
    } catch (error) {
      handleError(error)
    } finally {
      setIsSending(false)
    }
  }

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries({
      predicate: ({ queryKey }) =>
        queryKey[0] === 'balance' ||
        (queryKey[0] === 'account-asset' &&
          queryKey[1] === sender.address &&
          queryKey[2] === asset?.id) ||
        (queryKey[0] === 'select-assets' && queryKey[1] === sender.address) ||
        (queryKey[0] === 'account-info' && queryKey[1] === sender.address)
    })
  }

  const { mutateAsync: sendFromVault } = usePostSendFromVault({
    toasts: {
      success: SendSuccessToast
    },
    onMutate: async (params) => {
      const context: SendModalContext = {
        asset: asset as AccountAsset,
        sender: params.body.sender,
        receiver: params.body.receiver,
        amount: params.body.amount!
      }

      return context
    },
    onSuccess: async (data, params) => {
      props.onSuccess?.(params)
      await invalidateQueries()
    }
  })

  const { mutateAsync: sendToVault } = usePostSendToVault({
    toasts: {
      success: SendSuccessToast
    },
    onMutate: async (params) => {
      const context: SendModalContext = {
        asset: asset as AccountAsset,
        sender: params.body.sender,
        receiver: params.name,
        amount: params.body.amount!
      }

      return context
    },
    onSuccess: async (data, params) => {
      props.onSuccess?.(params)
      await invalidateQueries()
    }
  })

  const { mutateAsync: sendToAccount } = usePostSendToAccount({
    toasts: {
      success: SendSuccessToast
    },
    onMutate: async (params) => {
      const context: SendModalContext = {
        asset: asset as AccountAsset,
        sender: params.sender,
        receiver: params.receiver,
        amount: params.amount
      }

      return context
    },
    onSuccess: async (data, params) => {
      props.onSuccess?.(params)
      await invalidateQueries()
    }
  })

  const handleSendFromVault = async (senderNfd: NfdRecord) => {
    if (!activeAddress) {
      throw new Error('No active account')
    }

    if (!asset) {
      throw new Error('Asset not specified')
    }

    if (!senderNfd.owner) {
      throw new Error('Sender NFD owner not specified')
    }

    const name = senderNfd.name

    const body = {
      sender: senderNfd.owner,
      receiver: formState.receiver,
      receiverType: formState.receiverType,
      receiverCanSign,
      assets: [asset.id],
      amount: convertToBaseUnits(parseFloat(formState.amount), asset.decimals),
      note: formState.note
    }

    await sendFromVault({ name, body })
  }

  const handleSendToVault = async () => {
    if (!asset) {
      throw new Error('Asset not specified')
    }

    if (!isValidName(formState.receiver)) {
      throw new Error('Invalid NFD name')
    }

    const name = formState.receiver

    const body = {
      sender: sender.address,
      assets: [asset.id],
      amount: convertToBaseUnits(parseFloat(formState.amount), asset.decimals),
      note: formState.note,
      optInOnly: false
    }

    await sendToVault({ name, body })
  }

  const handleSendToAccount = async () => {
    if (!asset) {
      throw new Error('Asset not specified')
    }

    const params: SendToAccountParams = {
      sender: sender.address,
      receiver: formState.receiver,
      amount: convertToBaseUnits(parseFloat(formState.amount), asset.decimals),
      asset: asset.id
    }

    await sendToAccount(params)
  }

  return {
    isOpen,
    closeButtonRef,
    formState,
    sender,
    setSender,
    senders,
    asset,
    selectedAsset,
    setSelectedAsset,
    receiverNfd,
    setReceiverNfd,
    isLoading,
    isSending,
    isSubmitDisabled,
    isAlgoAsset,
    algoBalances,
    isFieldValid,
    showAmountValidState,
    error,
    handleInputChange,
    handleChangeReceiver,
    handleChangeReceiverType,
    handleSetMaxAmount,
    handleSubmit,
    handleOpen,
    handleClose
  }
}

function useModalState(props: UseSendModal) {
  const [isLocalOpen, setLocalIsOpen] = useState(false)

  const isOpen = props.isOpen ?? isLocalOpen
  const setIsOpen = props.setIsOpen ?? setLocalIsOpen

  return [isOpen, setIsOpen] as const
}

interface UseSender {
  senderProp: SendModalProps['sender']
}

function useSender({ senderProp }: UseSender) {
  const senders = useMemo<Sender[]>(() => {
    if (typeof senderProp === 'string') {
      return [
        {
          address: senderProp,
          nfd: undefined
        }
      ]
    }

    if (Array.isArray(senderProp)) {
      return senderProp.map((sender) => {
        if (typeof sender === 'string') {
          return {
            address: sender,
            nfd: undefined
          }
        }

        return {
          address: sender.nfdAccount as string,
          nfd: sender
        }
      })
    }

    return [
      {
        address: senderProp.nfdAccount as string,
        nfd: senderProp
      }
    ]
  }, [senderProp])

  const [sender, setSender] = useState<Sender>(senders[0])

  return {
    sender,
    setSender,
    senders
  }
}

interface UseAsset {
  assetProp: SendModalProps['asset']
  sender: Sender
  isOpen: boolean
}

function useAsset({ assetProp, sender, isOpen }: UseAsset) {
  const [selectedAsset, setSelectedAsset] = useState<AccountAsset | undefined>(undefined)

  const assetId = useMemo(() => {
    return typeof assetProp === 'number' ? assetProp : assetProp?.id || selectedAsset?.id
  }, [assetProp, selectedAsset])

  const isAssetQueryEnabled = isOpen && typeof assetProp === 'number' && !selectedAsset

  const sendAssetQuery = useAccountAsset({
    params: {
      account: sender.address,
      assetId: assetId as number
    },
    options: {
      enabled: isAssetQueryEnabled
    }
  })

  const isLoading = isAssetQueryEnabled && sendAssetQuery.isInitialLoading

  const asset = useMemo(() => {
    return typeof assetProp === 'number' ? sendAssetQuery.data : assetProp || selectedAsset
  }, [assetProp, selectedAsset, sendAssetQuery.data])

  const algoBalances = useAlgoBalances({ sender, asset, isOpen })

  return {
    asset,
    selectedAsset,
    setSelectedAsset,
    algoBalances,
    isLoading
  }
}

interface UseAlgoBalance {
  sender: Sender
  asset: AccountAsset | undefined
  isOpen: boolean
}

type AlgoBalances = {
  total: number
  maxSpend: number
}

function useAlgoBalances({ sender, asset, isOpen }: UseAlgoBalance): AlgoBalances | null {
  const { data: accountInfo } = useAccountInfo(sender.address, true, {
    enabled: isOpen
  })

  if (!accountInfo || !isAlgoAsset(asset)) {
    return null
  }

  const total = accountInfo.amount
  const maxSpend = total - accountInfo['min-balance'] - 1000

  return {
    total,
    maxSpend
  }
}

interface UseFormState {
  props: UseSendModal
  asset: AccountAsset | undefined
}

type FormStateReturnType = {
  formState: FormState
  setFormState: Dispatch<SetStateAction<FormState>>
  defaultState: FormState
}

function useFormState({ props, asset }: UseFormState): FormStateReturnType {
  const isOneOfOne = useMemo(() => isAssetOneOfOne(asset), [asset])

  const defaultState = useMemo<FormState>(() => {
    return {
      receiver: typeof props.receiver === 'object' ? props.receiver.name : props.receiver || '',
      receiverType: props.receiverType || 'account',
      amount: isOneOfOne ? '1' : props.amount?.toString() || '',
      note: ''
    }
  }, [isOneOfOne, props.amount, props.receiver, props.receiverType])

  const [formState, setFormState] = useState<FormState>(defaultState)

  useEffect(() => {
    const amount = isOneOfOne ? '1' : props.amount?.toString() || ''

    setFormState((prevState) => ({
      ...prevState,
      amount
    }))
  }, [asset, isOneOfOne, props.amount])

  return {
    formState,
    setFormState,
    defaultState
  }
}

interface UseValidation {
  formState: FormState
  sender: Sender
  asset: AccountAsset | undefined
  receiverCanSign: boolean
  algoBalances: AlgoBalances | null
  isOpen: boolean
}

function useValidation({
  formState,
  sender,
  asset,
  receiverCanSign,
  algoBalances,
  isOpen
}: UseValidation) {
  const isOptInQueryEnabled =
    isOpen && algosdk.isValidAddress(formState.receiver) && isAsaAsset(asset) && !receiverCanSign

  const { data: isOptedIn } = useQuery(
    ['asset-opt-in', formState.receiver, asset?.id],
    () => isAssetOptedIn(formState.receiver, asset?.id as number),
    {
      enabled: isOptInQueryEnabled
    }
  )

  const needsAssetOptIn = useMemo(() => isOptedIn === false, [isOptedIn])

  const senderMatchesReceiver = useMemo(() => {
    if (sender.nfd) {
      return sender.nfd.name === formState.receiver
    }
    return sender.address === formState.receiver
  }, [formState.receiver, sender])

  const amountExceedsMaxSpend = useMemo(() => {
    if (!asset || !isAlgoAsset(asset) || !formState.amount || !algoBalances) return false

    return parseFloat(formState.amount) > convertMicroalgosToAlgos(algoBalances.maxSpend)
  }, [asset, formState.amount, algoBalances])

  const amountExceedsBalance = useMemo(() => {
    if (!asset || !formState.amount) return false

    return parseFloat(formState.amount) > (asset?.amount as number)
  }, [asset, formState.amount])

  const isFieldValid = useCallback(
    (propertyName: keyof FormState, allowBlank = true) => {
      switch (propertyName) {
        case 'receiver':
          if (!formState.receiver) return allowBlank

          const isValidReceiver =
            algosdk.isValidAddress(formState.receiver) || isValidName(formState.receiver)

          const doesReceiverNeedToOptIn = isAsaAsset(asset) && needsAssetOptIn

          return isValidReceiver && !doesReceiverNeedToOptIn && !senderMatchesReceiver

        case 'receiverType':
          return formState.receiverType === 'account' || formState.receiverType === 'nfdVault'

        case 'amount':
          if (!formState.amount) return allowBlank

          const amount = parseFloat(formState.amount)

          if (isAlgoAsset(asset)) {
            return amount > 0 && !amountExceedsMaxSpend
          }
          return amount > 0 && !amountExceedsBalance

        case 'note':
          // Optional field
          return true

        default:
          return false
      }
    },
    [
      formState,
      asset,
      needsAssetOptIn,
      senderMatchesReceiver,
      amountExceedsBalance,
      amountExceedsMaxSpend
    ]
  )

  const isSubmitDisabled = useMemo(() => {
    const allowBlank = false
    return Object.keys(formState).some((key) => !isFieldValid(key as keyof FormState, allowBlank))
  }, [formState, isFieldValid])

  const error = {
    needsAssetOptIn,
    senderMatchesReceiver,
    amountExceedsMaxSpend,
    amountExceedsBalance
  }

  // While `0` is still considered invalid and disables form submission,
  // don't show the invalid state for the amount field
  const showAmountValidState = useMemo(() => {
    const zeroPattern = /^(0|0\.0*)$/
    if (zeroPattern.test(formState.amount)) {
      return true
    }

    return isFieldValid('amount')
  }, [formState.amount, isFieldValid])

  return {
    isFieldValid,
    isSubmitDisabled,
    showAmountValidState,
    error
  }
}
