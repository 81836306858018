import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { resolveAssetUrlProtocol } from 'helpers/arc19'
import { hasResponseStatus } from 'helpers/errors'
import { isAssetNft } from 'helpers/utilities'
import { AccountAsset, AlgoAccountAsset } from 'types/node'

export const FALLBACK_SRC = '/img/coin-line.svg'

export default function useAssetThumbnail(asset: AccountAsset) {
  const [src, setSrc] = useState<string | null>(null)

  // Type guard for AlgoAccountAsset
  const isAlgoToken = (asset: AccountAsset): asset is AlgoAccountAsset => {
    return asset.id === 0
  }

  // Total created is < 10,000
  const isNft = !isAlgoToken(asset) && isAssetNft(asset.total, asset.decimals)

  const getSrc = useCallback(async () => {
    // Use local image for ALGO
    if (isAlgoToken(asset)) {
      return '/img/asset-algo.svg'
    }

    // Handle ARC-3/ARC-19 URLs
    else if (isNft) {
      const resolvedUrl = resolveAssetUrlProtocol(asset.url, asset.reserve)

      if (resolvedUrl) {
        return resolvedUrl
      }

      return FALLBACK_SRC
    }

    // Check AlgoExplorer for logo
    else {
      try {
        const response = await axios.get(`/api/asset-logo/${asset.id}`)

        if (response.status === 200 && response.config.url) {
          return response.config.url
        }
      } catch (error) {
        if (!hasResponseStatus(error, [403, 404])) {
          console.error(`Error fetching asset thumbnail [${asset.unitName}]:`, error)
        }
      }

      return FALLBACK_SRC
    }
  }, [asset, isNft])

  useEffect(() => {
    getSrc().then(setSrc)
  }, [getSrc])

  return src
}
