import { BiLinkExternal } from 'react-icons/bi'
import Button from 'components/Button'
import { formatAssetBalance, isAssetNft, isValidName, truncateAddress } from 'helpers/utilities'
import type { ToastProps } from 'api/hooks/usePostTransaction'
import type { SendModalContext } from './SendModal.types'
import type { AccountAsset, AsaAccountAsset } from 'types/node'

export default function SendSuccessToast({
  context,
  explorerLink
}: ToastProps<unknown, SendModalContext>) {
  return (
    <>
      <p>Send successful</p>
      <p className="mt-1 text-sm text-gray-500">
        Sent{' '}
        <strong className="font-medium text-gray-900 dark:text-gray-100">
          {isNft(context?.asset) ? (
            <>
              {typeof context?.amount === 'number' && isMultiMint(context.asset)
                ? `${formatAssetBalance(context.amount, context.asset.decimals, true)} `
                : ''}
              {context?.asset.name}
            </>
          ) : (
            <span className="whitespace-nowrap">{`${
              typeof context?.amount === 'number'
                ? `${formatAssetBalance(context.amount, context.asset.decimals, true)} `
                : ''
            }${context?.asset?.unitName}`}</span>
          )}
        </strong>{' '}
        to{' '}
        <strong className="font-medium text-gray-900 whitespace-nowrap dark:text-gray-100">
          {isValidName(context?.receiver as string)
            ? (context?.receiver as string)
            : truncateAddress(context?.receiver)}
        </strong>
      </p>

      {explorerLink && (
        <div className="mt-5 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
          <a href={explorerLink} target="_blank" rel="noreferrer">
            <Button icon={BiLinkExternal}>View Transaction</Button>
          </a>
        </div>
      )}
    </>
  )
}

function isAsaAsset(asset: AccountAsset | undefined): asset is AsaAccountAsset {
  return !!asset?.id && asset.id !== 0
}

function isNft(asset: AccountAsset | undefined): boolean {
  if (isAsaAsset(asset)) {
    return isAssetNft(asset.total, asset.decimals)
  }
  return false
}

export function isMultiMint(asset: AccountAsset | undefined): boolean {
  if (isAsaAsset(asset)) {
    return isNft(asset) && asset.total > 1 && asset.decimals === 0
  }
  return false
}
