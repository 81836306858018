import { Combobox } from '@headlessui/react'
import Image from 'next/image'
import { useMemo } from 'react'
import { AiFillBank } from 'react-icons/ai'
import { HiCheck } from 'react-icons/hi2'
import Checkbox from 'components/Checkbox'
import Tooltip from 'components/ReactTooltip'
import useNfdLookup from './NfdLookup.hooks'
import { isVaultsSupported } from 'helpers/versions'
import { classNames } from 'helpers/utilities'
import { CLIENT_BASE_URL } from 'src/data/constants'
import type { NfdRecord } from 'api/api-client'
import type { ReceiverType } from './NfdLookup.types'

export interface NfdLookupProps {
  receiver: string
  onRecieverChange: (string: string) => void
  selectedNfd?: NfdRecord
  onNfdChange?: (nfd: NfdRecord | undefined) => void
  receiverType?: ReceiverType
  onReceiverTypeChange?: (type: ReceiverType) => void
  enableSendToVault?: boolean
  limit?: number
  readOnly?: boolean
}

export default function NfdLookup(props: NfdLookupProps) {
  const { enableSendToVault = false, readOnly = false } = props

  const {
    query,
    sendToVault,
    selectedNfd,
    suggestions,
    showSuggestions,
    isVaultLocked,
    isReadOnlyAccountSend,
    isReadOnlyVaultSend,
    getTooltipAddress,
    handleInputChange,
    handleNfdChange,
    handleSendToVaultChange
  } = useNfdLookup(props)

  const sendToVaultLabel = useMemo(() => {
    let label = 'Send to vault'

    if (!selectedNfd) return label

    if (!isVaultsSupported(selectedNfd)) {
      label += ' (upgrade required)'
    } else if (isVaultLocked) {
      label += ' (vault is locked)'
    }

    return label
  }, [selectedNfd, isVaultLocked])

  return (
    <>
      <Combobox as="div" value={selectedNfd} onChange={handleNfdChange}>
        <div className="relative mt-2">
          <Combobox.Input
            className={classNames(
              'w-full rounded-md border-0 py-2 pl-3 pr-10 shadow-sm ring-inset ring-gray-300 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6 dark:ring-transparent dark:focus:ring-brand-500 dark:caret-gray-400',
              readOnly
                ? 'bg-gray-100 text-gray-600 font-medium ring-0 focus:ring-0 dark:text-gray-400 dark:bg-gray-850/75'
                : 'bg-white text-gray-900 ring-1 focus:ring-2 dark:text-gray-100 dark:bg-gray-800'
            )}
            onChange={handleInputChange}
            displayValue={(nfd: NfdRecord) => nfd?.name || query}
            autoComplete="new-password"
            spellCheck="false"
            readOnly={readOnly}
          />

          {selectedNfd && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-2.5">
              <a
                href={`${CLIENT_BASE_URL}/name/${selectedNfd.name}`}
                target="_blank"
                rel="noreferrer"
                data-tooltip-id="nfd-badge"
                data-tooltip-content={getTooltipAddress(selectedNfd)}
                className={classNames(
                  'nfd-badge relative rounded-full overflow-hidden h-7 w-7 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-500 group border-2',
                  readOnly
                    ? 'border-gray-100 focus:ring-gray-100 dark:border-gray-850 dark:focus:ring-gray-850'
                    : 'border-white focus:ring-white dark:border-gray-800 dark:focus:ring-gray-800'
                )}
              >
                {sendToVault ? (
                  <span className="flex items-center justify-center h-6 w-6">
                    <AiFillBank className="h-6 w-6 text-gray-700 dark:text-gray-300" />
                  </span>
                ) : (
                  <Image src="/img/nfd.svg" alt="NFD" width={400} height={400} />
                )}
              </a>
              <Tooltip id="nfd-badge" />
            </div>
          )}

          {showSuggestions && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-950">
              {suggestions.map((nfd) => (
                <Combobox.Option
                  key={nfd.name}
                  value={nfd}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-brand-500 text-white' : 'text-gray-900 dark:text-gray-100'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames('block truncate', selected ? 'font-semibold' : '')}
                      >
                        {nfd.name}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-brand-500'
                          )}
                        >
                          <HiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>

      {selectedNfd && enableSendToVault && !isReadOnlyAccountSend && (
        <Checkbox
          id="send-to-vault"
          className="flex items-center mt-2 mx-1 text-sm"
          label={sendToVaultLabel}
          checked={sendToVault}
          onChange={handleSendToVaultChange}
          disabled={!isVaultsSupported(selectedNfd) || isVaultLocked || isReadOnlyVaultSend}
        />
      )}
    </>
  )
}
