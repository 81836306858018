import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { HiArrowUpRight } from 'react-icons/hi2'
import Alert from 'components/Alert'
import AlgoPrice from 'components/AlgoPrice'
import Button from 'components/Button'
import AssetThumbnail from 'components/DetailView/Vault/AssetThumbnail'
import Loading from 'components/Loading'
import NfdLookup from 'components/NfdLookup'
import Tooltip from 'components/ReactTooltip'
import SelectAsset from './SelectAsset'
import SelectSender from './SelectSender'
import useSendModal from './SendModal.hooks'
import { isAssetOneOfOne } from './SendModal.utils'
import { isVaultUnlocked } from 'helpers/vaults'
import { classNames, formatAssetBalance } from 'helpers/utilities'
import type { SendModalProps } from './SendModal.types'
import type { AccountAsset } from 'types/node'

export default function SendModal({
  children,
  showNote = false,
  showReceiver = false,
  ...props
}: SendModalProps) {
  const {
    isOpen,
    closeButtonRef,
    formState,
    sender,
    setSender,
    senders,
    asset,
    selectedAsset,
    setSelectedAsset,
    receiverNfd,
    setReceiverNfd,
    isLoading,
    isSending,
    isSubmitDisabled,
    isAlgoAsset,
    algoBalances,
    isFieldValid,
    showAmountValidState,
    error,
    handleInputChange,
    handleChangeReceiver,
    handleChangeReceiverType,
    handleSetMaxAmount,
    handleSubmit,
    handleOpen,
    handleClose
  } = useSendModal(props)

  const renderBalance = (asset: AccountAsset) => {
    if (isAlgoAsset(asset)) {
      if (!algoBalances) {
        return null
      }

      const { total, maxSpend } = algoBalances

      return (
        <>
          {formatAssetBalance(total, 6, true)}{' '}
          <span
            className="cursor-help"
            data-tooltip-id="algo-available-balance"
            data-tooltip-content="Available balance"
          >
            ({formatAssetBalance(maxSpend, 6, true)}
            <span className="sr-only">, Available balance</span>)
          </span>
        </>
      )
    }

    return formatAssetBalance(asset.amount, asset.decimals)
  }

  const renderAsset = () => {
    if (isLoading || !asset) {
      return null
    }

    return (
      <>
        <div className="mt-8">
          <div className="flex items-center -my-1.5">
            <AssetThumbnail
              asset={asset}
              className="relative h-12 w-12 mr-4 flex-none rounded-full overflow-hidden bg-black/5 dark:bg-white/5"
            />
            <div className="min-w-0">
              <p className="text-sm truncate">{asset.name}</p>
              <dl className="text-sm font-normal">
                <dt className="sr-only">Quantity</dt>
                <dd className="truncate font-mono text-gray-700 dark:text-gray-400">
                  <span className={classNames(isAssetOneOfOne(asset) ? 'hidden' : 'mr-2')}>
                    {renderBalance(asset)}
                  </span>
                  <span className="text-gray-500">{asset.unitName}</span>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        {isOpen && isAlgoAsset(asset) && <Tooltip id="algo-available-balance" />}
      </>
    )
  }

  const renderFormFields = () => {
    if (isLoading || (props.asset && !asset)) {
      return (
        <div className="flex flex-col items-center justify-center py-32">
          <Loading />
        </div>
      )
    }

    return (
      <>
        {senders.length > 1 && (
          <div>
            <SelectSender selected={sender} setSelected={setSender} senders={senders} />
          </div>
        )}

        {props.asset === undefined && (
          <div>
            <SelectAsset
              sender={sender.address}
              selectedAsset={selectedAsset}
              setSelectedAsset={setSelectedAsset}
            />
          </div>
        )}

        {(!props.receiver || showReceiver) && (
          <div>
            <label
              htmlFor="receiver"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >
              Recipient
            </label>
            <div className="mt-2">
              <NfdLookup
                receiver={formState.receiver}
                selectedNfd={receiverNfd}
                onNfdChange={setReceiverNfd}
                onRecieverChange={handleChangeReceiver}
                receiverType={props.receiverType}
                onReceiverTypeChange={handleChangeReceiverType}
                enableSendToVault
                readOnly={!!props.receiver}
              />
            </div>
          </div>
        )}

        {!isAssetOneOfOne(asset) && !props.amount && (
          <div>
            <label
              htmlFor="amount"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >
              Amount
            </label>
            <div className="relative mt-2 flex items-center">
              <input
                id="amount"
                name="amount"
                type="text"
                autoComplete="off"
                required
                className={classNames(
                  showAmountValidState
                    ? 'ring-gray-300 focus:ring-brand-600 dark:bg-gray-800 dark:ring-transparent dark:focus:ring-brand-500'
                    : 'ring-red-300 focus:ring-red-500 dark:bg-red-600/20 dark:ring-transparent dark:focus:ring-red-500',
                  'block w-full font-mono rounded-md border-0 py-2 pr-14 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 dark:text-gray-100 dark:placeholder:text-gray-600 dark:caret-gray-400'
                )}
                value={formState.amount}
                onChange={handleInputChange}
                aria-invalid={!isFieldValid('amount')}
                aria-describedby="amount-error"
              />
              <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <Button
                  size="xs"
                  onClick={handleSetMaxAmount}
                  className="uppercase focus:ring-brand-500 dark:focus:ring-brand-500"
                >
                  Max
                </Button>
              </div>
            </div>
            {!showAmountValidState && (
              <p className="mt-2 text-sm text-red-500" id="amount-error">
                Invalid amount
              </p>
            )}
          </div>
        )}

        {showNote && (
          <div>
            <label
              htmlFor="note"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >
              Note
            </label>
            <div className="mt-2">
              <textarea
                rows={4}
                name="note"
                id="note"
                className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:py-1.5 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-gray-100 dark:ring-transparent dark:placeholder:text-gray-600 dark:focus:ring-brand-500 dark:caret-gray-400"
                value={formState.note}
                onChange={handleInputChange}
                placeholder="Write an optional note..."
              />
            </div>
          </div>
        )}
      </>
    )
  }

  const renderErrorMessage = () => {
    if (error.needsAssetOptIn) {
      if (receiverNfd) {
        return (
          <Alert type="warning" className="mt-4" alignIcon="top">
            <p className="text-sm text-amber-700 dark:text-gray-400">
              Recipient NFD&apos;s deposit account is not opted-in to asset{' '}
              <span className="text-amber-700 font-medium font-mono dark:text-gray-300">
                {asset?.id}
              </span>
              .{' '}
              {isVaultUnlocked(receiverNfd) && (
                <>
                  Try sending to{' '}
                  <span className="text-amber-700 font-medium dark:text-gray-300">
                    {receiverNfd.name}
                  </span>
                  &apos;s unlocked vault&nbsp;instead.
                </>
              )}
            </p>
          </Alert>
        )
      }

      return (
        <Alert type="warning" className="mt-4">
          <p className="text-sm text-amber-700 dark:text-gray-400">
            Recipient must opt-in to asset{' '}
            <span className="text-amber-700 font-medium font-mono dark:text-gray-300">
              {asset?.id}
            </span>
          </p>
        </Alert>
      )
    }

    if (error.amountExceedsMaxSpend) {
      return (
        <Alert type="warning" alignIcon="top" className="mt-4">
          <p className="text-sm text-amber-700 dark:text-gray-400">
            Amount exceeds&nbsp;
            <AlgoPrice
              price={algoBalances?.maxSpend}
              className="font-mono text-amber-700 font-medium dark:text-gray-300"
              symbolClassName="ml-0.5 -mr-1"
              exactTrim
            />{' '}
            available balance. Learn more about Algorand&apos;s{' '}
            <a
              href="https://developer.algorand.org/docs/get-details/accounts/#minimum-balance"
              className="font-medium text-brand-500 hover:text-brand-600 no-underline group"
              target="_blank"
              rel="noopener noreferrer"
              title="Algorand Developer Portal: Accounts Overview"
            >
              minimum balance requirement
              <BiLinkExternal className="inline mx-1 h-4 w-4 text-gray-400 dark:text-gray-600" />
            </a>
          </p>
        </Alert>
      )
    }

    if (error.amountExceedsBalance) {
      return (
        <Alert type="warning" className="mt-4">
          <p className="text-sm text-amber-700 dark:text-gray-400">
            Amount exceeds asset balance of{' '}
            <span className="text-amber-700 font-medium font-mono dark:text-gray-300">
              {asset?.amount}
            </span>
          </p>
        </Alert>
      )
    }

    if (error.senderMatchesReceiver) {
      return (
        <Alert type="warning" className="mt-4">
          <p className="text-sm text-amber-700 dark:text-gray-400">
            Sender and recipient cannot be the same!
          </p>
        </Alert>
      )
    }

    return null
  }

  return (
    <>
      {children && React.isValidElement(children)
        ? React.cloneElement(children, { onClick: handleOpen })
        : null}

      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[60]"
          initialFocus={closeButtonRef}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/75 transition-opacity dark:bg-black/75" />
          </Transition.Child>

          <div className="fixed inset-0 z-[60] overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center md:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative flex-1 transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 dark:bg-gray-900">
                  <div className="flex items-center flex-row-reverse justify-center sm:flex-col">
                    <div className="flex sm:mx-auto h-10 w-10 sm:h-12 sm:w-12 items-center justify-center rounded-full bg-green-100 dark:bg-gray-300/10">
                      <HiArrowUpRight
                        className="h-5 w-5 sm:h-6 sm:w-6 text-green-600 dark:text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mr-3 text-center sm:mr-0 sm:mt-3">
                      <Dialog.Title
                        as="h3"
                        className="text-xl sm:text-2xl font-semibold leading-6 text-gray-900 dark:text-gray-100"
                      >
                        {props.label || 'Send'}
                      </Dialog.Title>
                    </div>
                  </div>

                  {renderAsset()}

                  <div className="mt-8">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                      {renderFormFields()}

                      {renderErrorMessage()}

                      <div className="pt-4 sm:pt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-brand-600 px-4 py-2.5 font-medium text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600 sm:col-start-2 disabled:opacity-25"
                          disabled={isSubmitDisabled}
                        >
                          {`Send ${
                            isAssetOneOfOne(asset) || !asset?.unitName ? '' : asset.unitName
                          }`}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-4 py-2.5 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 dark:bg-gray-750 dark:ring-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-gray-100"
                          onClick={handleClose}
                          ref={closeButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>

                  <div
                    className={classNames(
                      isSending ? 'flex' : 'hidden',
                      'absolute inset-0 items-center justify-center bg-white/50 dark:bg-gray-900/50'
                    )}
                  >
                    <Loading />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
