import { useEffect, useState } from 'react'
import { immer } from 'zustand/middleware/immer'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { ExplorerLink } from 'helpers/explorerLink'
import { ALGORAND_NETWORK } from 'src/data/constants'
import { AlgorandNetwork, DEFAULT_EXPLORERS, EXPLORERS, Explorer } from 'src/data/explorers'

const DEFAULT_SELECTED_EXPLORER: Explorer = DEFAULT_EXPLORERS[ALGORAND_NETWORK as AlgorandNetwork]

export type ExplorerStore = {
  explorers: Explorer[]
  selectedExplorer: Explorer
  setSelectedExplorer: (explorer: Explorer) => void
  lookupByTxnId: (txnId: string) => string
  lookupByGroupId: (groupId: string) => string
  lookupByAddress: (address: string) => string
  lookupByBlock: (block: string | number) => string
  lookupByAppId: (appId: string | number) => string
  lookupByAssetId: (assetId: string | number) => string
}

const emptyState: ExplorerStore = {
  explorers: EXPLORERS,
  selectedExplorer: DEFAULT_SELECTED_EXPLORER,
  setSelectedExplorer: () => {
    return
  },
  lookupByTxnId: () => '',
  lookupByGroupId: () => '',
  lookupByAddress: () => '',
  lookupByBlock: () => '',
  lookupByAppId: () => '',
  lookupByAssetId: () => ''
}

const usePersistedStore = create<ExplorerStore>()(
  immer(
    persist(
      devtools((set, get) => ({
        explorers: EXPLORERS,
        selectedExplorer: DEFAULT_SELECTED_EXPLORER,
        setSelectedExplorer: (explorer: Explorer) => {
          set((state) => {
            state.selectedExplorer = explorer
          })
        },
        lookupByTxnId: (txnId: string) => {
          const explorer = get().selectedExplorer
          return new ExplorerLink(explorer).tx(txnId)
        },
        lookupByGroupId: (groupId: string) => {
          const explorer = get().selectedExplorer
          return new ExplorerLink(explorer).group(groupId)
        },
        lookupByAddress: (address: string) => {
          const explorer = get().selectedExplorer
          return new ExplorerLink(explorer).account(address)
        },
        lookupByBlock: (block: string | number) => {
          const explorer = get().selectedExplorer
          return new ExplorerLink(explorer).block(block)
        },
        lookupByAppId: (appId: string | number) => {
          const explorer = get().selectedExplorer
          return new ExplorerLink(explorer).app(appId)
        },
        lookupByAssetId: (assetId: string | number) => {
          const explorer = get().selectedExplorer
          return new ExplorerLink(explorer).asset(assetId)
        }
      })),
      {
        name: 'nfd-explorer',
        version: 5,
        migrate: (persistedState: unknown, version: number): ExplorerStore => {
          const state = persistedState as ExplorerStore

          if (version === 2 || version === 3 || version === 4) {
            state.explorers = EXPLORERS
            state.selectedExplorer = DEFAULT_SELECTED_EXPLORER
          }

          return state
        }
      }
    )
  )
)

// @see https://github.com/pmndrs/zustand/issues/1145#issuecomment-1207233036
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useExplorerStore = ((selector: any, compare: any) => {
  const store = usePersistedStore(selector, compare)
  const [hydrated, setHydrated] = useState(false)
  useEffect(() => setHydrated(true), [])

  return hydrated ? store : selector(emptyState)
}) as typeof usePersistedStore
