import { classNames } from 'helpers/utilities'
import AlgoPrice from './AlgoPrice'

interface PriceTagProps {
  price: number
  className?: string
}

export default function PriceTag({ price, className = '' }: PriceTagProps) {
  return (
    <span
      className={classNames(
        'inline-flex items-center px-2.5 py-0.5 leading-normal rounded-full text-sm font-semibold bg-gray-100 text-gray-800 dark:bg-gray-100/10 dark:text-gray-300',
        className
      )}
    >
      <AlgoPrice price={price} />
    </span>
  )
}
